var estados = [
	// NORTE
	{
		nome: 	'Acre',
		sigla: 	'ac',
		id: 	'#ac',
		path: 	'm0 176.4c1.9-3.7 1.1-8.5 4.4-11.5 12 5.2 25.5 5.9 37.5 11.2 8.5 3.2 15.5 9.3 23.4 13.7 12.4 7.3 25.7 13.1 38 20.6-3.9 3.5-8.2 6.5-12.4 9.6-2.3 0.1-4.6-0.2-6.9-0.1-1.4 2.2-2.3 5-4.7 6.3s-5 0.3-7.5-0.1c-8.4-2-17.1 0.3-25.5-1.7-1.5-7.5 2.4-14.4 2.9-21.7-3.4 2.8-5.8 7.3-10.5 8.1-4.6 0.8-9.4 1.4-14 0.5-1.2-2-1.7-4.3-3-6.1-2.6-1.1-5.5-0.9-8.2-1.8-1.3-2.5-0.8-5.8-3.1-7.7-2.1-2-4.3-4.1-4.8-7.1-1-4.4-2.8-8.5-5.6-12v-0.2z'
	},
	{
		nome: 	'Amazonas',
		sigla: 	'am',
		id: 	'#am',
		path: 	'm140.2 41.3c2.1-0.9 4.4-1.4 6.6-2.1 4.3 4.3 6.2 10.1 7.5 15.9 1.3 6.6 4.1 13 3.9 19.9 0.2 2.2-2 3-3.5 4 2.5 3.6 6.5 5.7 9.5 8.8 1.1 0.8 2.6 2.7 4.1 1.6 4.7-3.3 0.9-10.5 4.9-14.1 3.9-1.7 7 1.6 9.9 3.6 3.1 0.7 4.9-2.3 6.3-4.5 1.9-2.8 2.4-6.3 3.7-9.4 0.3-1.2 1.6-2.1 2.9-2 4.3 0 8.5 1.1 12.8 1.4 0.7 7.9 3.1 15.7 7.4 22.4 4.4 3.7 9.9 6.1 15.6 7.4 5.2 1.2 10.6 3.9 13.1 8.9 0.4 2.6-2 4.6-3 6.7-8.7 14.8-12.9 31.6-20.2 47.1-1.2 2.7-3.1 5.2-3.9 8.1 1 5.1 3.5 10.4 2.2 15.6-3.3 4.6-2.4 10.5-2.5 15.8-10 2.7-20.4 0.7-30.6 1.1-7.4 0.1-15.3 1.2-22.3-2.1-4.3-1.9-6.8-6.3-11.3-7.7-5.4-1.9-12.1-0.7-15.6 4.1-1.8 2.4-1.8 5.5-2.1 8.3-6.1 1.2-11.6 4.3-15.8 8.9-3.6-4.1-9.3-2-13.8-1-13.4-8.2-28-14.3-41.5-22.5-6-3.6-11.5-8.1-17.9-11.1-11.9-5.6-25.1-7.4-37.6-11.1 0.2-0.5 0.6-1.5 0.8-1.9-0.6-1.8-1.6-3.4-1.6-5.3 0.8-1.7 2.2-2.9 3.2-4.5 0.7-2.7 0.3-5.8 2.1-8.2 2.6-3.4 6.1-6.1 9.8-8.2 4.1-2.4 9.1-1.2 13.5-2.4 3.9-1.7 7-5.2 11.3-6.1 2.6-0.1 3.6 2.9 5 4.6 3.6-5.8 4.1-12.7 5.5-19.2 1.4-6.3 1.1-12.9 3.4-19 1.4-4 2.9-8.7 0.5-12.6-2.7-5.2-8.7-9.8-7.2-16.3 1.9-4.3 7.5-2.4 11.1-3.8 2.3-0.5 2.4-3.1 2.9-5-4.1-0.6-8.6-0.3-12.3-2.4-2.2-1.4-1.7-4.5-0.5-6.4 1.2-2.8 4.7-3.3 7.4-3.4 4.8 0.1 9.5 1 14.3 1.7 0.2-1.5 0.5-2.9 0.8-4.4 1.7 0.9 3.3 2 4.8 3.2 3.2-1.3 7.1-3.2 10.4-0.9 4.7 3.8 7.4 9.4 10.8 14.3 3.7 0.5 7.3-0.9 10.9-0.5 0.9 1.1 1.7 2.3 2.2 3.6 3-1.6 3.7-5.1 5.6-7.5 3.6-3.1 8.5-3.9 13-5 2.4-1.3 2.2-4.4 3.5-6.4z'
	},
	{
		nome: 	'Amapá',
		sigla: 	'ap',
		id: 	'#ap',
		path: 	'm311.4 15.1c1-2.3 1.8-4.7 3.6-6.6 2.2 0.2 3.2 2.4 4.1 4.2 2.6 5.9 4.3 12.1 5.2 18.5 0.5 3.3 1.8 6.8 4.7 8.7 2.6 1.8 5.5 3 8.3 4.4-0.5 1.8-0.8 3.6-1.1 5.5-2.3 0.6-4.9 0.9-6.3 3-2.2 2.6-2 7.2-5.8 8.3-5.5 2.1-8.6 7.5-12.2 11.8-2.1 2.4-2.6 5.5-3.5 8.4-1.5-1.4-3.2-2.7-4.3-4.5-1-2.1-1.3-4.4-2.4-6.5-1.3-1.7-3.2-2.8-4.6-4.3-0.6-3.6 0.4-7.4-1.4-10.7-1.3-2.6-2.9-5-4.8-7.2-2.2-2.8-6.2-2.9-8.7-5.3-2.6-2.5-6.4-2.1-9.7-2.1-0.2-1.6-0.3-3.2-0.4-4.7 1.9 1 3.7 2.4 5.9 2.7 2-0.8 3.8-2.1 5.9-2.7 3.5 0.2 6.6 2.1 10 2.3 2.5 0.1 4.8-1.2 6.3-3.1 4.8-6 8.3-12.9 11.2-20.1z'
	},
	{
		nome: 	'Pará',
		sigla: 	'pa',
		id: 	'#pa',
		path: 	'm251.1 34.1c5.6-1.3 11.5 0 17.2-0.1 0.5 3.1 0.7 6.3 1.4 9.4 3.4 1.5 7.8-0.6 10.6 2.2 2.1 1.9 4.8 2.7 7.2 3.9 2.2 1.9 3.6 4.5 5 6.9 2 3.5 0.2 7.8 1.6 11.4 1.3 1.7 3.4 2.7 4.7 4.4 1.4 3 1.7 6.5 4.3 8.8 2.1 2.3 6.1 4.5 8.8 1.9 0.8-2.5 0.5-5.5 2.4-7.6 3.5-4 6.2-9.3 11.6-11.3 4.3-1.1 4.5-6.1 6.7-9.3 1.7-1.3 4.1-1.1 6-2 0.8-1.5 1.1-3.2 1.6-4.8 0.7 3.7 1.6 7.6-0.4 11-1.1 2.3-3 5.7 0 7.3 5.8 2.2 13.1 0.6 18.1 5 2.5 2.9-0.1 6.7-0.8 9.8-0.4 1.9-2.3 4.8 0 6.1 4.4 0 6.2-4.5 8.8-7.3 2-2.3 5.4-2 8-1.4 5.5 1.3 10.5 4.2 15.8 6.2-1.6 3.2-2.6 6.7-2 10.3 1 4.1-2.7 7-4.4 10.4-1.6 2.4-0.9 5.5-2.1 7.9-2.5 2.8-4.7 5.8-5.9 9.4-0.5 2.2-2.7 3.2-4.1 4.6-2.2 1.9-3.4 4.7-5.9 6-4 2.3-8.1 4.6-10.8 8.3-1.9 2.3-1.3 6.2 1.1 7.8 1.3 0.6 2.7 0.9 4 1.4-2 3.3-3.8 6.9-5.7 10.3-2.2 4-7.1 5.5-9.8 9.1-3.3 4.2-2.9 10.9 1.3 14.4-3 9.8-13.2 15.1-16.4 24.8-7.7 0-15.3-1.7-23-2.1-17.4-0.6-34.9-1.2-52.2-3.5-3.9-0.5-8.1-0.2-11.9-1.7-2.6-3.3-5.7-6.3-8.6-9.3-1.2-3.7 0.2-7.9-1.4-11.6-1.5-4.1-5.1-7-8.7-9.2-0.4-2.4-2.2-4.9-1.6-7.4 8.9-16.2 13.2-34.5 21.9-50.9 1.6-3.2 4.5-5.9 5-9.6-0.3-3.7-3.3-6.4-5.9-8.7-5.6-4.4-13.2-4.2-19.2-7.7-1.7-1-3.3-2.1-4.9-3.1-2.1-4.3-4.3-8.7-5.3-13.4-1.4-6-1.2-12.2-1.2-18.4 4.6-1.3 8.6-3.7 12.8-5.9 4-2 7.4-5.8 12.2-5.5 5.8 0.4 11.3 3 17.2 2-1.6-2.7-6-6-3.1-9.2z'
	},
	{
		nome: 	'Rondonia',
		sigla: 	'ro',
		id: 	'#ro',
		path: 	'm140.6 193.6c2.8-3.7 8.2-4.2 12.3-2.5 2.8 1.4 4.8 3.9 7.4 5.7 2.9 2.2 6.5 3.1 10 4 0.8 3 1.7 6.1 2.2 9.2 0.4 4.4-1.8 8.7-0.5 12.9 1.2 4 3.9 7.6 7.7 9.4 3.8 1.5 8 1.6 12 2.4-1.2 1.8-2.6 3.9-1.9 6.2 1.2 2.6 3.7 4.4 4.8 7.1-0.7 2.2-2.8 3.6-4.2 5.4-2.6 2.7-3.6 6.4-4.4 9.9-4.5-0.8-9.1-0.2-13.5-1-4.2-0.8-6.3-5.4-10.5-6.1-1.7-0.6-4.3-0.1-4.9-2.3-1.7-4.4-7-4.7-11-5.3-9.2-0.9-18.5-5-24-12.6-3.8-7.2-1.2-15.6-1.6-23.3 3.4-2.7 6.2-6.3 10.5-7.6 2.4-1 5.2-1.2 7.3-2.8 1.2-2.8 0.3-6.2 2.3-8.7z'
	},
	{
		nome: 	'Raraima',
		sigla: 	'rr',
		id: 	'#rr',
		path: 	'm191.5 0h2.6c4.3 4.5 8.1 11.2 5.4 17.5-1.6 6.5-4 14-0.2 20.2 2.6 5 4.7 10.5 9.1 14.2 0.1 3.1 0.1 6.1 0.2 9.2-4.8-0.6-9.7-1.8-14.5-1.1-3.4 0.6-4.3 4.1-5.2 6.9-0.9 3.1-2.5 6-4.2 8.7-3.8-3-9.4-5.9-13.9-2.8-3.5 3.4-2.8 8.7-3.6 13.1-2.2-2.1-4.5-4.1-6.9-5.9 2.9-7.7-0.3-15.6-2.1-23-1.7-7.1-3.3-15-9.1-20-2.7-2.9-6.9-3.2-10.3-4.8-3-4.4-0.2-10.9-4.2-14.8-2.6-2.3-5.5-4.5-7.2-7.6 2.5 0 5-0.3 7.5 0.3 2.2 0.5 3.5 2.6 5 4.2 3-0.3 6-1 9-0.7 2.3 1 2.9 3.9 4.1 5.8 3.5-1 4-4.9 5.9-7.5 3.7-2.1 8.1-2.4 12.1-3.9 7.1-1.9 13.7-5.2 20.5-8z'
	},
	{
		nome: 	'Tocantins',
		sigla: 	'to',
		id: 	'#to',
		path: 	'm345.2 177c-0.3-3.9 3-6.4 5.7-8.6 3.9 4.6 6 11.3 12 13.6 3.9 1.8 7.6 3.8 11.4 5.7 4.3 2.1 9.1 2.4 13.7 3.3 1.4 2.2 3.1 4.3 2.7 7.1-0.1 3.8 2.1 7.2 2.7 10.9 0.5 4.4-4.5 7.4-3 11.7-3 2.3-4.9 5.6-7 8.6-1.7 3.1-0.2 6.5 2.1 8.8 0.1 1.9 0.5 3.7 1.2 5.5-2.6 5.2-0.4 10.8-0.1 16.3 0.2 1.2 0.5 2.4 1 3.5-0.5 1.4-1.1 2.7-1.6 4.1-4.5 0-9.1 0.7-13.4-1.1-2.4-1.2-4.4 1-6 2.5-1.2-2.6-3.7-3.5-6.4-3.7-2.3-2.1-4.9-4.6-8.3-4.7-2.7-0.3-4.6 1.8-6.5 3.5-2.4-0.4-4.9-0.8-7.3-1.3 0.4-2.8 1.7-6-0.2-8.6-1.3-2.2-4-1.8-6.1-1.4-2.2-7.3-2.1-15.1-1.6-22.7 1.3-6.1 5.3-11.6 5-18-0.1-1.5-1.6-2.4-2.4-3.5 4.3-8.2 12.9-13.6 15.7-22.6 1.5-3.7-3.5-5.4-3.3-8.9z'
	},
	// NORDESTE
	{
		nome: 	'Alagoas',
		sigla: 	'al',
		id: 	'#al',
		path: 	'm508.1 210c0.9-1 1.6-2.4 2.9-3.1 3 2 5.5 4.9 9.1 5.7 3.7 0.6 7.4-0.1 11-0.7-3.1 3.3-5.7 6.9-8.1 10.7-1.9-0.9-4-1.6-5.7-2.9-2.1-2.4-2.6-5.8-5-8-1.3-0.8-2.8-1.2-4.2-1.7z'
	},
	{
		nome: 	'Bahia',
		sigla: 	'ba',
		id: 	'#ba',
		path: 	'm447 208c4.2-4.9 11-6.4 14.9-11.6 1.8 4 2.8 8.3 4.3 12.5 0.4 1.7 2.1 2.4 3.4 3.4 1.1-2.9 1.1-7 4.4-8.6 2.9-0.4 5.8 0.3 8.6-0.4 1.9-1.1 2.7-4 5.3-3.8 7.7-0.1 7.9 11.5 15.7 10.9 1.7 4.4 5.3 8.6 4.1 13.6-0.8 3.4-6 3.4-6.2 7 0.2 3.1 3 6 0.6 8.9 2.4 1.9 4.8 3.8 7.3 5.7-4.9 3.2-10.6 6.3-12.6 12.2-5.8 16.5-4.1 34.2-6.3 51.3-0.6 4.7-1.1 9.6-3.8 13.7-1.8 2.8-4.3 5.3-5 8.7-0.6 2.8-0.4 5.8-0.5 8.7-3.6-2.1-7.3-4.2-10.8-6.4-1.3-3.8-3.8-7-5.2-10.8 3.7-3.1 5-8.1 8.9-10.9 2.4-1.3 1.9-4.6 0.3-6.3-1.9-2.2-4.7-3.6-7.4-4.2-2.8-0.3-5.3 1-8 1.4-2.2-1.9-2.7-5.1-4.8-7.1-1.6-1-3.4-1.4-5.1-2-4.2-1.4-7.3-4.9-11.5-6.3-4.1-1.2-8.5 0.8-12.5-0.4-2.8-1.7-2.3-5.7-4.8-7.6-3.8-2.1-8.2 0.1-11.3 2.4-5.7 3.8-11 9.9-18.4 9.5 0.9-2.9 2.3-5.6 2.8-8.6 0.3-4.7-4.9-7.4-5.1-11.8 1-3.7 3.7-7.2 2-11.1 0.9-2-0.2-3.9-0.9-5.8 0.3-1.7-0.1-3.4-0.5-5.1 0.3-3.1 2.2-6.1 0.4-9.1 0.6-0.3 1.7-1 2.2-1.3-1.7-2.1-4-3.6-5.6-5.8 0.1-3.4 3.7-5.2 5-8.2 1.1-0.4 2.3-0.7 3.4-1.2 1.2-1.2 1.4-3.8 3.6-3.5 2.8 3.3 5.1 8 10 8.3 4.3-0.2 8-2.9 11.7-4.8 5.5-3.1 7.6-10.3 5.1-16.1 1.8-0.3 3.7-0.6 5.5-1 1.2 0.9 2.4 1.9 3.7 2.8 0.9-0.4 1.9-0.9 2.8-1.3 2.9 2.5 7.5 3.1 10.3 0.1z'
	},
	{
		nome: 	'Ceará',
		sigla: 	'ce',
		id: 	'#ce',
		path: 	'm468.2 108.7c9 0.9 17.1 5.2 25.2 8.9 5.8 2.8 11.7 6.2 15.3 11.7 1.8 2.8 4 5.2 6.7 7.1-1.5 2.9-2.7 5.9-4.4 8.6-1.1 1.9-3.3 2.6-4.6 4.3-0.5 2.4-0.4 4.9-0.5 7.4-3.3-0.3-7.4-0.3-9.1 3.1-1.3 6.7-2.1 13.5-1.3 20.3-3.1-1.6-6-3.9-9.5-4.7-3.2-0.7-6.4-1.1-9.5-1.8 0.6-4.2-1.6-7.7-3.9-11-2.5-6.2-4.7-12.8-4-19.5 0.2-4.1 0.8-8.2 0-12.2-0.5-2.9-2.4-5.2-3.3-7.9 0.1-2 0.6-3.9 0.9-5.8 1.2-2.7 1.8-5.6 2-8.5z'
	},
	{
		nome: 	'Maranhão',
		sigla: 	'ma',
		id: 	'#ma',
		path: 	'm392.9 85.6c6.8 2.2 14.5 3.6 19.5 9 3.6 3.7 2 9.2 1.8 13.7 4.4-2 8.7-5 13.8-4.9 8.5 0.1 16.2 4.2 24.5 5.3-0.5 2-0.4 4.4-1.9 5.9-2.5 1.5-6 0.8-8.1 3-1.4 1.3-1.1 3.4-1.5 5.1-1.8 1.6-4.2 2.9-5 5.4-1.3 5.6-1.1 11.4 0.5 17-1.1 3.7-4.3 6.9-3.7 11 0.5 3.3 2.7 6.1 4 9.2-5.2 0.4-10.4-2.6-15.6-1.1-3.3 2-4.9 5.8-8 8-3.7 3.6-9.8 1.9-13.3 5.7-2.5 3.2-2.5 7.4-3.8 11.1-3.3-1.4-6.3-3.9-10-3.9-5.6 0.2-10.5-2.8-15.4-4.9-6-4-10.8-9.5-15.6-14.8 2.8-4.8 5.8-9.4 7.8-14.5 0.8-3.6-3.2-3.9-5.6-4.5-1.6-2.3 1-4.2 2.5-5.6 3.5-3.3 8.7-4.4 11.4-8.6 1.5-2.4 4.1-3.8 6.2-5.7 1.3-2.2 1.7-4.9 3.2-7.1 1.1-1.7 2.7-3.1 3.9-4.8 1.1-2.5 0.4-5.6 2-8 2.1-3.6 5.5-7 4.6-11.5-0.6-3.3 0.3-6.6 1.8-9.5z'
	},
	{
		nome: 	'Paraíba',
		sigla: 	'pb',
		id: 	'#pb',
		path: 	'm511.9 163.8c2.2-1 3.4-3.4 5.2-5 1.4-0.1 2.8-0.2 4.3-0.1 0.4 4.3-3.1 10 1.1 13.2 4.7-0.2 5.2-6.2 6.6-9.6 4.3 1.1 5.2 6.3 9 8 5.2 1.1 10.2-1.3 14.9-3.2v6.4c-0.4 3.7-1.2 7.4-2 11.1-6.2 0-11.1 4.1-16.8 5.8-2.5 0.7-5.1 0.8-7.7 1-1.9 0.4-4.3 1.9-5.8 0-3.8-3-0.2-7.6 0-11.3-0.7-2-2.8-2.9-4.6-3.5-2.6 1.9-4.2 5.1-7.3 6.2-3.6 0.9-7.3 0.8-11 1.1 2-6.4-0.3-13.1 1.8-19.5 4.1 0 8.3 0.5 12.3-0.6z'
	},
	{
		nome: 	'Pernanbuco',
		sigla: 	'pe',
		id: 	'#pe',
		path: 	'm470.7 189.1c-0.3-4.6-1.4-9.4 0.8-13.7 4 1.6 8.3 2.4 12.5 3 3.6 0.5 6.6 2.9 9.6 4.8 0.6 1.6 1 4.1 3.3 3.9 3.9 0.3 7.8-0.2 11.6-0.8 3.8-0.5 6.3-3.6 8.9-6.1-0.7 3.2-2.9 6.4-1.6 9.7 1.1 3.4 4.5 6.5 8.2 5.7 4.3-0.9 8.8-1.1 13-2.6 4.3-1.6 8.2-4.2 12.8-5.2-2.6 8.6-8.9 15.3-15.6 20.9-4.4-0.5-8.8 1.1-13.2 0.7-3.6-0.8-5.6-4.4-9-5.6-2.7-0.7-4.5 1.4-6.2 3.1-1.5-0.1-3.1-0.2-4.7-0.3-3.4-4-6-9.9-12-10.4-3.4-0.7-6.3 1.3-8.1 4-2.5 0.1-5.1-0.2-7.6 0.3-2.1 0.6-3.6 2.4-5.2 3.7-0.9-3.3-2.1-6.6-3.6-9.7 2.9-0.5 6.2-2 6.1-5.4z'
	},
	{
		nome: 	'Piauí',
		sigla: 	'pi',
		id: 	'#pi',
		path: 	'm455.7 108.7c3.1 0 6.2 0 9.3 0.1 0.1 3.7-2.2 6.6-4.7 9.2 0.6 0.3 1.7 0.8 2.3 1.1-2.7 4.3 1.4 7.9 2.6 11.9 0.8 5.3-0.1 10.7 0 16 0 5 1.8 9.7 3.3 14.4 1 3.7 4.1 6.3 4.9 10.1-1.9 0.6-4.2 0.9-5 3.1-2.8 4.8-1.1 10.4-1.2 15.6-2.7 0.7-5.7 1.1-7.6 3.4-2.1 2.6-4.5 5-7.6 6.3-3.6 1.5-5.8 5-9.1 6.8-3-0.3-5.6-2.6-8.8-1.9-3.3-3.3-7.9-1.1-11.8-0.3-2.3 1.7-0.5 4.5-0.2 6.6 1.3 4-1.5 8.2-5.1 10-2.9 1.4-5.7 3.5-9.1 3.7-3.8-0.5-5.1-4.8-7.6-7.1-1.6-1.7-4.1-0.6-6-0.5 1.6-3.1 3.2-6.6 2.2-10.2-0.7-3.3-3.1-6.4-2.3-9.9 1.7-2 3.7-3.8 4.6-6.4 1.9-3.2 1.6-7.2 3.5-10.4 3-2.7 7.7-1.6 11-4 4-2.3 6.3-6.4 9.7-9.3 4.4 0 8.5 1.9 12.9 1.7 2.2-0.4 4.3-2.3 4.1-4.6-0.8-2.9-3-5.1-3.8-8-0.8-4.8 4.9-8.1 3.3-13-1.2-4.6-1.3-9.5-0.3-14.1 1.3-2 3.6-3 5-4.8 0.4-1.5 0.4-3 0.5-4.5 3-0.8 6.6-0.7 8.7-3.3 1.9-2.1 2-5 2.3-7.7z'
	},
	{
		nome: 	'Rio Grande do Norte',
		sigla: 	'rn',
		id: 	'#rn',
		path: 	'm509.6 151c5-2.9 6.4-8.8 9-13.4 4 1.4 8 3.4 12.4 3.2 4.4 0 9.5-1 13.1 2.3 5.7 5.2 7.2 13.3 8.3 20.6-4.3 1.5-8.6 4.1-13.3 3.4-2.5-1.8-3.4-4.9-5.6-6.8-2.1-1.5-5-1.2-7.1 0.1-0.9 1.5-1.4 3.1-1.9 4.7 0.1-2.6 0.8-5.5-1-7.7-1.8-2.1-4.8-2.5-7.4-2-3.1 0.8-3.6 5.5-7.1 5.6-3.1 0.5-6.4 0.4-9.4-0.7 2.9-0.8 7.1 1.2 9-1.8 1.1-2.3 0.8-5 1-7.5z'
	},
	{
		nome: 	'Sergipe',
		sigla: 	'se',
		id: 	'#se',
		path: 	'm509.5 213.4c1.8 2.4 3 5.1 4.6 7.6 1.7 2.6 4.8 3.4 7.5 4.6-2.8 6.1-5.6 12.3-9.7 17.6-1.7-1.2-3.4-2.5-5.1-3.8 0.1-2.9-0.9-5.6-2.1-8.3 2.1-1.2 4.7-2.3 5.7-4.8 2.1-4.1-0.1-8.7-0.9-12.9z'
	},
	// CENTRO
	{
		nome: 	'Goiás',
		sigla: 	'go',
		id: 	'#go',
		path: 	'm419 318.75c-2.25 1.87-3.12 4.63-3.5 7.5-0.88 5.13-4 9.5-5.88 14.37-2.12 5.88-1.37 12.63-4.62 18.13-2.38 4.5-8 6.25-10.12 11-1.63 2.75-2.76 6.37-5.76 8-2.87 1.63-6.5 2.37-8.37 5.37-2.75 4-4.37 8.63-7.37 12.5-1.88 2.63-4.13 5.13-5.26 8.13 0.63 4.75 3 10-0.5 14.25 1 1.5 2.38 2.88 3.13 4.5-0.37 1.75-1 3.38-1.63 5 2.38 0 4.88-0.12 7.26 0.25 3.74 1.25 7 4.13 11.12 4.63 4 0.62 8.12 1.24 12 2.74 3.75-2.24 8-3.87 11-7.12 3.38-3.88 9.38-2.5 12.62-6.25 1.76-1.87 4.38-1.87 6.76-2.13 4.62-3.24 10.24-0.5 15.24-2.24 3.88-1.5 7.5 0.62 10.88 2.37 1.5-0.87 3.12-1.75 4.5-2.87 1-4.13-0.5-8.13-2.12-11.88 0.24-3.5 3.37-5.38 5.62-7.62-1-3.26-3-6.26-3.12-9.76 0.74-3.62 1.24-7.24 1.87-11 1.37-2.5 4.5-2.74 6.87-3.74 0.13-4.26 0.13-8.76 1.5-12.88 2.76-2 5.88-3.38 7.38-6.62 2.25-3.76 6.88-0.88 9.62 0.37-2.24-5.25-6.37-9.5-7.62-15.25-5.75 0.38-11.75 0.88-17-1.75-1.5 2-3 4.13-4.38 6.25-2.87-1.5-4.87-4.25-6.24-7.12-1.5 0.5-3.13 1-4.63 1.5-1.63-3.38-4.13-7.13-8.25-7.38-3.62-0.38-4.88 5-8.62 4.12-4.5-0.37-9.5-1-13-4.12-0.26-3.75 1.37-7.38 0.62-11.25zm12.84 39.97h26.99v15.08h-26.99v-15.08z'
	},
	{
		nome: 	'Mato Grosso do Sul',
		sigla: 	'ms',
		id: 	'#ms',
		path: 	'm247.3 323.1c6.3-0.3 11.6 3.9 17.7 4.9 3 1.3 5.7-0.5 8.2-1.8 3.6 1.1 7.5 2 10.8-0.3 1.8 5 6.8 7.6 8.8 12.6-0.5 1.8-1.9 4.2-0.1 5.7 2.9 2.1 7 0.2 9.9 2.4 4.8 3.3 11 2.5 16.2 4.9-1.1 3.5-2.1 7.1-0.9 10.7-1.5 3.6-5.5 4.9-7.9 7.7-1.3 3-1.3 6.3-1.9 9.4-4.7 1.7-5.1 6.8-5.8 11-5.2 2.1-9.8 5.3-14.3 8.7-1.6 1.5-2.2 3.8-3.4 5.6-1.8 2-4.3 3.1-5.8 5.4-1.2 2-1.7 4.4-2.3 6.6-1.8-1.1-3.6-2.1-5.6-2.7-1.9 4-6.4 2.8-9.8 2.1-1.8-7.2-1.6-14.8-4.3-21.7-1.2-3.2-4.1-6.3-7.8-5.8-2.9-0.1-4.9 2.2-7.1 3.6-3.6 0.3-7.2 0.5-10.8 0.3-3.1-0.4-4.6-3.6-5.2-6.3-1.6-6.9-0.4-14.2-2-21.1-0.8-3.9-2.2-8-0.6-11.9 2.5-6.6 5.4-13.1 8.1-19.7 5.1 1 8.6-3.1 12.6-5.4 1.9-1 2.4-3.2 3.3-4.9z'
	},
	{
		nome: 	'Mato Grosso',
		sigla: 	'mt',
		id: 	'#mt',
		path: 	'm223.8 176c1.9 1.9 4 3.7 4.9 6.2 1.3 3.5 0.4 7.2 1.1 10.8 0.7 2.5 2.9 3.9 4.6 5.6 2.9 2.4 4.3 6.9 8.5 7.3 19.6 2.6 39.4 4 59.1 4.5 10.1 0 20 2.8 30.1 2-0.7 4.4-2.2 8.6-4 12.6-2.5 6-1.5 12.7-1.3 19 0.2 5.4 2.9 10.6 2.4 16.1-0.6 3.8-3 7-4.5 10.6-1.9 4.4-1.2 9.5-3.2 13.9-1.3 2.6-4 4-6 6-2.4 2.2-3.1 5.5-5 8-2.3 1.8-5.4 2.3-7.4 4.4-3.1 3.5-4.4 8.1-7.3 11.8-1.8 2.6-4.2 4.9-4.7 8.1 0 2.8 1.1 5.4 1.5 8.1-1.4-1.1-2.9-2.1-4.1-3.4-1.2-2.2-1.1-4.8-1.6-7.1-4-1.7-5 4.6-8.8 3.4-1.8-0.3-3.5-0.9-5.3-1.3-1.6 0.9-3.3 1.8-5 2.7-3.8-1-7.6-2-11.2-3.7-3.5-1.6-7.5-2.3-11.2-1.2-1.6 0.9-1.9 2.9-2.7 4.4-3.4 1.9-6.2 5.3-10.3 5.5-0.8-2.5-1.1-5.6-3.4-7.2-3.5-2.8-9-3.6-10.8-8.1-0.5-2.7 1.1-5.3 1.3-7.9-0.4-1.8-1.7-3.5-3.6-3.8-7-0.9-13.9 1.5-20.9 0.7-3.8-0.6-2-5.4-2.3-8 1.1-4.1-3.6-5.2-5.4-8 0.3-3.6 5.5-2.9 6.4-6.3 0.5-3.5-2.6-6.2-2.7-9.7-0.1-3.7-2.4-7.4-1-11.1 1-4.6 5.5-7.1 7.7-11 0.9-4.1-2.5-7-4.6-10 0.5-2.5 2.9-4.4 4.1-6.8-4.2-1.7-8.7-2.4-13.2-3-4.1-0.4-7.3-3.9-8.6-7.6-1.2-4.2 1-8.4 0.5-12.6-0.3-3-1.4-5.9-2.1-8.9 7.1 0.3 14.1-0.5 21.2-0.1 8.4 0.1 17.7 1.7 25.3-2.6 1.2-4.4 0.4-9 1-13.4 3.1-2 2.4-5.7 2.5-8.9z'
	},
	// SUDESTE
	{
		nome: 	'Espirito Santo',
		sigla: 	'es',
		id: 	'#es',
		path: 	'm459.4 338.7c0.4-1.4 0.9-2.8 1.6-4.1 2.9-0.5 5.6 1 8 2.4 4 2.3 7.9 4.7 12 6.8-1.5 6.5-5.5 11.9-8 18-4.1 7.8-8.3 15.6-12.1 23.6-1-1.3-1.8-3-3.3-3.9-1.9-0.8-3.9-1.1-5.9-1.5-1.2-3.5-4-5.9-7.5-6.9 0.9-4.4 5.3-5.2 8.4-7.4 2.8-2.9 4.7-6.8 5.4-10.7-0.3-2.7-1.4-5.2-2-7.7 1 0 3 0.2 4 0.2-1.3-2.9-2.5-5.9-3.4-9 0.7 0 2.1 0.2 2.8 0.2z'
	},
	{
		nome: 	'Minas Gerais',
		sigla: 	'mg',
		id: 	'#mg',
		path: 	'm405.6 288.7c4-2.5 7.6-7.3 12.8-6.2 1.5 2.5 1.9 6 4.7 7.4 4 2.2 8.6 0.3 12.9 0.7 4.9 1.6 8.5 5.8 13.6 7.1 4.4 0.6 4.1 6.3 7.7 8.1 2.4 1.6 5.1-0.5 7.6-0.7 3.1-0.7 5.6 1.8 7.8 3.6-4.4 3.4-6.7 8.5-10.2 12.8-1.1 3.6 1.5 7.1 3.4 10-2.2 0-4.8-1-6.7 0.7-1.8 1.5-3.6 3.1-5.1 5-1.4 2.1 0.2 4.7 0.6 6.9-3 3.2-1 7.2-0.2 10.7-0.2 2.5-1.6 4.7-2.8 6.8-2 3.3-6.6 3.2-8.7 6.3-1.4 1.9-1.6 4.4-2 6.6-3.1 3.1-5.4 6.7-6.8 10.8-1.1 1.9-1.6 4.9-4.2 5.4-6.6 1-13.4 0.4-20 1.9-4.4 0.9-7.9 3.7-11.7 5.8-1.9-0.9-4-2.3-6.2-1.9-2.5 0.2-3.8 2.6-5.2 4.3-1.4-1.9-3.5-3.5-4.3-5.8 0.7-3.5 2.9-7.2 1.4-10.9-0.8-2.5-3.8-2.6-5.9-3.4-1.7-5.7-1.1-12.2-4.5-17.3-1.9-2.7-5.4-3.3-8.5-2.9-5.3 0.7-10.8-0.5-15.9 1.8-3.4-5-10-3.9-15.1-5.5-4.6-1.2-9.4-0.5-13.6 1.4 0.8-2.8 1.6-5.7 2.6-8.6 3.7-0.6 6.1-3.4 8.8-5.7 3.7-1 7.4-1.8 10-4.9 0.6 0.4 1.7 1.2 2.3 1.6 1.3-0.9 2.4-2 3.7-2.9 2.7 0.1 5.5 0.6 8.2-0.4 3.7-1.8 6.8 2.9 10.4 1.3 2.1-0.7 4-2 5.1-3.9 0.4-2.9 0.6-5.9 1.5-8.6-1.1-0.6-2.3-1.1-3.5-1.7 1.6-1.8 3.6-3.3 4.8-5.4 0.2-2.1-0.8-4.1-1.4-6-1.8-3.3 0.5-6.8 0.3-10.2 1.9-0.7 3.9-1.1 5.8-2 1-2.3-0.3-5 0.6-7.5-0.1-1.2-0.1-2.5-0.1-3.8 1.5 0 3.1 0.1 4.6 0.2 0.3-2 0.8-3.9 1.2-5.8 1 0.9 2 1.7 3 2.6-0.7 2.6-1.6 6.4 1.5 7.7 5.8 2.1 11.2-2.4 15.7-5.5z'
	},
	{
		nome: 	'Rio de Janeiro',
		sigla: 	'rj',
		id: 	'#rj',
		path: 	'm433 393.2c5.2-4.2 4.6-13.1 11.1-16.2 3.1-0.2 3.9 3.3 4.7 5.6 2.3 0.6 4.6 1.1 6.9 1.8 1.9 0.6 1.5 3.1 1.8 4.6-1.5 2.9-3.5 5.6-5.1 8.6-3.7 5.7-11 7.5-17.4 8.3-10.8 1.4-21.6 3.5-32.3 5.7 0.4-1.6 0-3.5 1.2-4.7 2-1.3 4.9-1 6.5-2.9 0.9-3.4-3.8-3.9-5.8-5.3 8.3-6 19.3-2.2 28.4-5.5z'
	},
	{
		nome: 	'São Paulo',
		sigla: 	'sp',
		id: 	'#sp',
		path: 	'm324.5 360.3c4.8-1.8 9.8 0.3 14.6 0.9 2 0.5 4.3 0.3 6 1.6 1.1 2.1 1.8 4.3 2.9 6.4 1.3-0.4 2.6-0.7 3.8-1.1v-3.5c5.7-0.3 11.5-0.8 17.2-0.6 6.4 4.3 2.8 14 7.3 19.6 1.4 1.1 3.2 1.1 4.8 1.6 0.3 3.4-1.4 6.5-1.9 9.8-0.3 2.2 1.4 3.7 2.7 5.2 1.6 1.6 2.6 3.7 3.9 5.7 2.8-1.1 4.4-3.7 6.2-6 2.2-0.6 3.7 1.4 5.3 2.5 1.3-0.5 2.6-1 3.8-1.5 1.1 0.5 2.2 1.1 3.4 1.6-4.1 1.6-5.8 6-4.8 10.1-4 1-8.1 2.1-11.5 4.6-3.4 2.2-5.6 5.9-9.2 7.8-3.1 1.6-6.6 2.4-9.6 4.4-3.5 2.3-6 5.8-8.4 9.3-1.8-1.4-3.6-2.7-5.6-3.9-0.7 0.4-2.3 1.2-3.1 1.6 0-2 0.3-3.9 0.8-5.8-3.5-1.7-7.5-0.2-11.2-0.9-1.4-2.1 1.5-2.9 2.8-3.8-2.4-4.4-5.3-8.8-5.6-13.8-0.4-2.7-0.2-6.7-3.5-7.6-2.8-0.5-5.7 0-8.5-0.4-3-0.8-4.9-3.9-8.1-4.4-1.9 0.1-3.6 1.2-5.5 1.8-2 0.8-3.9-0.6-5.8-1.2-4.1-1.9-8.7-1.5-13.1-1.7 2.6-2.5 5.9-3.7 9-5.1 3.8-1.9 1.6-7 3.9-9.9 1.2-0.6 2.3-1 3.6-1.4 0.6-3.6 0.9-7.3 1.8-10.9 2.4-1.6 5.3-2.9 6.8-5.7 1.3-2 2.2-4.6 4.8-5.3z'
	},
	// SUL
	{
		nome: 	'Parana',
		sigla: 	'pr',
		id: 	'#pr',
		path: 	'm284.4 409.3c3.4-1.9 4.5-5.8 6.4-9 1.1 0.7 2.1 1.3 3.1 2 3-0.2 6-0.5 9 0.1 3.2 0.6 6 2.4 9.2 2.8 2.4-0.2 4.5-1.4 6.8-1.9 2.8 1.3 5 3.8 8.2 4.2 2.7 0.4 5.3 0.1 8 0 0.4 5.9 1.7 11.7 4.7 16.7-1.1 2.1-2.3 4.6-0.9 6.9 2.1 3.2 6.5 1.9 9.7 2 0.4 1.8 0.2 4.1 1.9 5.3 1.6 1.4 3.8 0.7 5.6 0.7 1.1 0.9 2.1 1.8 3.2 2.7-1.4 2.9-2.5 5.9-3.5 8.9-3.8-1.2-7.8-2.8-11.7-1.7-1.9 0.6-2.8 2.6-4 4-3.5-0.6-5.4-4.6-9.1-4.3-2.6-0.4-4.6 1.3-6.6 2.7-1.5 1.3-3.7 1.1-5.5 1.5-0.1 1.9-0.2 3.8-0.2 5.7-3.7 0.2-6.8-1.8-10.1-3-3.8-0.8-7.8 0.1-11.5-1-4.1-1.2-8.2-2.6-12.5-2.9-0.8-2.9-1-7.2-4.6-7.9-2.2 0.2-4.3 0.9-6.4 1.5 1.1-7.2 3.4-14.1 3.8-21.4-0.1-3.3 2.4-5.8 3-8.9 0.5-2.4 1.8-4.5 4-5.7z'
	},
	{
		nome: 	'Santa Catarina',
		sigla: 	'sc',
		id: 	'#sc',
		path: 	'm328.7 452.2c4.7-1.6 6.8 4.9 11.4 4 1.6-0.2 2.6-1.5 3.7-2.4 2.8-3.7 7.6-0.7 11.2 0.2-2.3 10.1 6.6 18.9 3.8 28.9-2.2 7-10.1 8.8-15.7 12.2-2 1.5-4.3-0.2-6.4-0.4 1.8-2.3 4.5-4.5 4.1-7.7-0.1-2.5-2.3-4.4-4.5-5.4-3.3-1.5-7.3 0.7-10.4-1.4-6.5-3.7-9.9-11.4-16.9-14.3-8.7-3.2-17.6 1.2-26.3 0.7 1.9-3.6 2.1-7.6 2.2-11.6 4.1 0.5 8.1 2 12.2 3 4.2 1.2 9-0.5 13 1.9 3.1 1.6 6.8 2.8 10.1 1.3 2-0.9 1.7-3.4 2.3-5.1 2.4-0.8 4.5-2.1 6.2-3.9z'
	},
	{
		nome: 	'Rio Grande do Sul',
		sigla: 	'rs',
		id: 	'#rs',
		path: 	'm290.1 469.6c6.2-0.9 13.1-3 19.1 0.2 6.4 4.1 10.1 12 17.7 14.5 3.7 0.8 9.7-1.5 10.8 3.7-2.4 3.5-6 6.1-7.4 10.1 2.8 0.1 5.7 0.1 8.6 0.1-4.1 5.8-6.6 12.3-8.7 19-2.7 8.7-9.5 15.2-14.3 22.8-2.7 4.5-7.1 7.5-10.6 11.4-2.7 2.8-3.1 6.8-4.6 10.2-0.9 2.3-3 3.8-4.6 5.5-2.7 2.6-4.4 5.9-6.6 8.9h-1.3c-0.3-1.9-0.9-3.9-0.8-5.8 0.5-3.8 3.7-6.5 4.4-10.2 0.5-2.2-2.2-3.1-3.4-4.5-3-1.7-2.1-5.6-3.6-8.2-2.6-1.8-5.9-2.7-7.9-5.2-2.2-2.9-5.2-4.8-8.7-5.5-0.5-0.6-0.9-1.2-1.4-1.8-3.5-1.6-6-4.7-7.7-8.1-2 1.9-3.8 3.8-5.6 5.8-4.3-4.3-5-11.3-10.3-14.7-3.8-2.7-8.6-0.6-12.8-1.4-0.9-5.8 5-9 8.8-12.1 7.8-6 11.9-15.4 19.6-21.4 4-3 6.9-7 10.4-10.4 1.8 0.2 3.6 0.5 5.5 0.3 1.3-1.1 2.4-2.6 3.6-3.9 3.9 0.8 7.9 1.4 11.8 0.7z'
	}
];


if($('#mapa-brasil').length > 0) {

	estados.forEach(function(estado){
		let nome 	= estado.nome;
		let sigla  	= estado.sigla;
		let id 		= estado.id;
		let path 	= estado.path;

		$(id).attr({
			d : path,
			'data-nome-estado' : nome,
			'data-sigla' : sigla
		});
	});

	$('#mapa-brasil path, #mapa-brasil rect').click(function(){
		let nome = $(this).data('nome-estado');

		$('#mapa-brasil').find('.active').removeClass('active');
		$(this).attr('class', 'active');

		$('h1.estado').text(nome);
	});
}
