var lazyImages = (function(){
	var jaLazy = false;
	var register = function(){
		$('lazy-image').each(function(){
			let width 	= $(this).attr('width') 	|| 0;
			let height 	= $(this).attr('height') 	|| 0;

			let scale 	= (parseInt(height) / parseInt(width)) * 100;

			let template = `<div class="lazyimage" style="width: ${width}px;max-width: 100%;">
				<div class="lazyimage-wrapper" style="padding-bottom: ${scale}%;position:relative;">
					<div class="canvas" style="position:absolute;left:0;top:0;overflow:hidden;width:100%;height: 100%"></div>
				</div>
			</div>`;

			$(this).append(template);
			$(this).addClass('registrado');
		});
	};
	var verify = function() {
		$('lazy-image.registrado:not([loading])').each(function(){
			if(this.getBoundingClientRect().top <= (window.innerHeight + 200)) {
				let canvas = this.querySelector('.canvas');
				let img = document.createElement('img');
				let self = this;

				img.style.opacity 	= 0;
				img.src 			= $(this).attr('src') 		|| '';
				img.alt 			= $(this).attr('alt') 		|| '';
				img.width 			= $(this).attr('width') 	|| 0;
				img.height 			= $(this).attr('height') 	|| 0;

				canvas.appendChild(img);

				this.setAttribute('loading','');

				img.addEventListener('load', function(){
					this.style.opacity = 1;
					self.setAttribute('loaded','');
				});
			}
		});
	};

	register();
	verify();

	$(window).scroll(function(){
		if(jaLazy) return;

		setTimeout(function() {
			jaLazy = false;
		}, 100);

		verify();
	})

})();