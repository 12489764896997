function animateScroll(){
	var wp = $(window).scrollTop();
	var wh = $(window).height();

	$('[data-animate]').each(function(){
		var animacao = $(this).data('animate');
		var offset = $(this).offset().top;
		var pos = offset - wh - 100;

		if(wp >= pos){
			$(this).addClass('animated '+animacao)
		}else{
			$(this).removeClass('animated '+animacao)
		}
	});
}

animateScroll();

$(window).scroll(function(){
	animateScroll();
})