$('.btn-toggle-menu').click(function(e){
	e.preventDefault();

	$('#menu').addClass('open');

	var bgMenu = document.createElement('div');

	$(bgMenu).addClass('bg-menu');
	$(bgMenu).click(function(){
		$('#menu').removeClass('open');
		$('.bg-menu').fadeOut(600, function() {
			$(this).remove();
		});
	});

	document.body.appendChild(bgMenu)
	$(bgMenu).fadeIn(600)
});
